import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import CryptoTable from './components/CryptoTable';
import './App.css';

function App() {
  return (
    <Router>
    <div className="App">
    <header className="App-header">
    <h1>Possessed Portfolio</h1>
    </header>
    <CryptoTable />
    </div>
    </Router>
  );
}

export default App;
  