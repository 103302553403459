	    import React, { useEffect,useMemo, useState } from 'react';
    import { useTable, useSortBy} from 'react-table';
    import mockData from '../data/mockData.json';
    import styles from './CryptoTable.module.css';
    import { useLocation } from 'react-router-dom';
    import ReactModal from 'react-modal'; 
    ReactModal.setAppElement('#root');
    

        
    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text).then(() => {
        console.log('Copied to clipboard');
        // You can also display a message to the user indicating the copy was successful
      }).catch(err => {
        console.error('Could not copy text: ', err);
      });
    };

    function roundAfterNonZeroDigits(value, significantDigits = 5) {
      if (value > 0 && value < 0.1) {
        // Convert the number to scientific notation and then to a string
        const scientificString = value.toExponential();
        
        // Extract the coefficient and the exponent
        const [coefficient, exponent] = scientificString.split('e').map(part => parseFloat(part));
        
        // Calculate the total number of decimal places needed
        const totalDecimalPlaces = significantDigits - 1 - Math.floor(Math.log10(coefficient)) + Math.abs(exponent);
        
        // Round the number to the calculated decimal places
        return Number(value).toFixed(totalDecimalPlaces);
      }
      return value;
    }
    
    function formatBalance(balance) {
      if (balance < 1e3) return formatUSD(balance).toString();
      if (balance >= 1e3 && balance < 1e6) return +(balance / 1e3).toFixed(1) + "K";
      if (balance >= 1e6 && balance < 1e9) return +(balance / 1e6).toFixed(1) + "M";
      if (balance >= 1e9 && balance < 1e12) return +(balance / 1e9).toFixed(1) + "B";
      if (balance >= 1e12) return +(balance / 1e12).toFixed(1) + "T";
    }
    
    function formatUSD(value, usebaseprice = false, baseTokenPrice=0) {
      if (usebaseprice){
        value = value / baseTokenPrice;
      }
      if (value > 0 && value < 0.1){
        return roundAfterNonZeroDigits(value).toString();
      }
      // Check if the value has meaningful decimals
      const decimalPart = value % 1;
      if (decimalPart > 0) {
        // If so, format to two decimal places
        return value.toFixed(2);
      } else {
        // Otherwise, round to the nearest whole number
        return Math.round(value).toString();
      }
    }
    
    const CryptoTable = () => {
      const [isModalOpen, setIsModalOpen] = useState(false);
      const [iframeUrl, setIframeUrl] = useState('');
      const openModal = (tokenAddress) => {
        const url = `https://birdeye.so/tv-widget/${tokenAddress}?chain=${selectedBlockchain}&chartType=area&chartInterval=15&chartLeftToolbar=show`;
        setIframeUrl(url);
        setIsModalOpen(true);
      };
      
      const closeModal = () => {
        setIsModalOpen(false);
        setIframeUrl('');
      };

      const [data, setData] = useState([]);
      const [walletAddress, setWalletAddress] = useState('');
      const [selectedBlockchain, setSelectedBlockchain] = useState('');
      const [filterZero, setFilterZero] = useState(true);
      const [searchInput, setSearchInput] = useState('');
      const location = useLocation();
      const [totalBalanceUSD, setTotalBalanceUSD] = useState(0);
      const [useBaseToken, setUseBaseToken] = useState(false);
      const [baseTokenValue, setBaseTokenValue] = useState(0);
      
      useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const addressFromURL = urlParams.get('walletAddress');
        const chainFromURL = urlParams.get('chain');
        
        const addressFromStorage = addressFromURL || localStorage.getItem('walletAddress') || '';
        const chainFromStorage = chainFromURL || localStorage.getItem('chain') || '';
        
        setWalletAddress(addressFromStorage);
        setSelectedBlockchain(chainFromStorage);
      }, [location]);
      
      useEffect(() => {
        if (walletAddress && selectedBlockchain) {
          localStorage.setItem('walletAddress', walletAddress);
          localStorage.setItem('chain', selectedBlockchain);
          
          const fetchTokenDataWithPrices = () => {
            const options = {
              method: 'GET',
              headers: {
                'x-chain': selectedBlockchain,
                'X-API-KEY': 'a69a1b2087244ef58bf5aa5f7609c111'
              }
            };
            
            fetch(`https://public-api.birdeye.so/v1/wallet/token_list?wallet=${walletAddress}`, options)
            .then(response => response.json())
            .then(response => {
              if (response.success) {
                setTotalBalanceUSD(response.data.totalUsd);
                setData(response.data.items);
                
                if (selectedBlockchain == "solana"){
                  response.data.items.forEach(token => {
                    if (token.address === 'So11111111111111111111111111111111111111111') { 
                      //console.log(token) // SOL token address
                      setBaseTokenValue(token.priceUsd);
                    }  
                  });
                }
                
              } else {
                console.error('Failed to fetch token data with prices:', response);
              }
            })
            .catch(err => console.error(err));
          };
          
          fetchTokenDataWithPrices();
        }
      }, [walletAddress, selectedBlockchain, useBaseToken]);
      const columns = useMemo(() => [
        {
          Header: 'Token',
          accessor: 'tokenData',
          Cell: ({ row }) => {
            const icon = row.original.icon;
            const name = row.original.name;
            const address = row.original.address; // Get the address from the row data
            return (
              <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => copyToClipboard(address)}>
              <img src={icon} alt="logo" className={styles.logoImage} />
              <span style={{ marginLeft: '8px', cursor: 'pointer' }}>{name}</span>
              </div>
            );
          },
          sortType: (rowA, rowB) => {
            return rowA.original.balance - rowB.original.balance;
          }
        },  
       {
          Header: 'Value',
          accessor: 'valueUsd',
          Cell: ({ value }) => {
            if (useBaseToken) {
              return `${formatUSD(value, useBaseToken, baseTokenValue)} Sol`;
            } else {
              return `$${formatUSD(value, useBaseToken, baseTokenValue)}`;
            }
          },
          sortType: (rowA, rowB) => {
            // Compare the numerical balance values for sorting
            return rowA.original.valueUsd - rowB.original.valueUsd;
          }
        },
        {
          Header: 'Balance',
          accessor: 'balanceSymbol',
          Cell: ({ row }) => {
            const balance = row.original.balance;
            const symbol = row.original.symbol;
            const uiAmount = row.original.uiAmount
            const formattedBalance = formatBalance(uiAmount);
            // Format and return the combined value
            return `${formattedBalance} ${symbol}`;
          },
          sortType: (rowA, rowB) => {
            // Compare the numerical balance values for sorting
            return rowA.original.balance - rowB.original.balance;
          }
        },
        {
          Header: 'Price',
          accessor: 'priceUsd',
          Cell: ({ row }) => (
            <span
            onClick={() => openModal(row.original.address)}
            style={{ cursor: 'pointer' }}
            >
            {`$${formatUSD(row.original.priceUsd, false, baseTokenValue)}`}
            </span>
          ),
        },
        { 
          Header: 'Links', 
          accessor: 'address',  
          Cell: ({ row }) => {
            const address = row.original.address;
            const symbol = row.original.symbol;
    
            return (
              <div style={{ display: 'block', alignItems: 'center' }}>
              <a href={`https://t.me/share/url?url=${address}`} target="_blank" style={{ display: 'block' }}>TG bot</a>
              <a href={`https://birdeye.so/token/${address}?chain=${selectedBlockchain}`} target="_blank" style={{ display: 'block' }}>Birdeye</a>
              <a href={`https://raydium.io/swap/?inputCurrency=${address}&outputCurrency=sol&fixed=out`} target="_blank" style={{ display: 'block' }}>Raydium</a>
              <a href={`https://rugcheck.xyz/tokens/${address}`} target="_blank" style={{ display: 'block' }}>Rugcheck</a>
              <a href={`https://solscan.io/token/${address}`} target="_blank" style={{ display: 'block' }}>Solscan</a>
              </div>
            );
          }
        }
        ], [useBaseToken, baseTokenValue]);
      
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = useTable({ columns, data }, useSortBy);
      const filteredRows = rows.filter(row => {
        if (filterZero && (row.original.valueUsd === 0 || isNaN(row.original.valueUsd))) {
          return false;
        }
        // Convert all relevant fields to strings and check if any field contains the search input as a substring.
        const rowValues = Object.values(row.original);
        const searchString = searchInput.toLowerCase();
        
        return rowValues.some(value => String(value).toLowerCase().includes(searchString));
      });
      return (
        <div> 
        <span className={styles.portfolioFormInputWrapper}>
        <label>
        <input
        type="checkbox"
        checked={useBaseToken}
        onChange={e => setUseBaseToken(e.target.checked)}
        />
        SOL value
        </label>
        </span>
        <span className={styles.portfolioFormInputWrapper}>
        <label>
        <input
        type="checkbox"
        checked={filterZero}
        onChange={e => setFilterZero(e.target.checked)}
        />
        >$0
        </label>
        </span>
        <input
        type="text"
        className={styles.searchInput}
        value={walletAddress}
        onChange={e => setWalletAddress(e.target.value)}
        placeholder="Wallet Address"
        />
        <select className={styles.searchInput}value={selectedBlockchain} onChange={e => setSelectedBlockchain(e.target.value)}>
        <option value="">Select Chain</option>
        <option value="solana">Solana</option>
        <option value="ethereum">Ethereum</option>
        <option value="avalanche">Avalanche</option>
        <option value="bsc">BSC</option>
        <option value="optimism">Optimism</option>
        <option value="polygon">Polygon</option>
        <option value="base">Base</option>
        <option value="zksync">ZKSync</option>
        </select>
        
        
        <input
        className={styles.searchInput}
        value={searchInput}
        onChange={e => setSearchInput(e.target.value)}
        placeholder="Search..."
        />
        
        <div className="crypto-table-card">
        <table {...getTableProps()} className={styles.table}>
        <thead>
        {/* Total Balance row */}
        <tr>
        <td>Total Balance:</td>
        <td colSpan={headerGroups[0].headers.length - 1}>
        {useBaseToken ? 
          `${formatUSD(totalBalanceUSD, useBaseToken, baseTokenValue)} Sol` :
          `$${formatUSD(totalBalanceUSD, useBaseToken, baseTokenValue)}`
        }
        </td>
        </tr>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(column => (
            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
            {column.render('Header')}
            </th>
          ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        
        {/* Data rows */}
        {filteredRows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
            {row.cells.map(cell => (
              <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
            ))}
            </tr>
          );
        })}
        </tbody>
        </table>
        </div>
        <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)'
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            height: '600px'
          },  
        }}
        >
        <button onClick={closeModal}>Close</button>
        <iframe
        width="100%"
        height="100%"
        src={iframeUrl}
        frameBorder="0"
        allowFullScreen
        ></iframe>
        </ReactModal>
        </div>
      );};
    
    export default CryptoTable; 
